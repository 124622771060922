import { FormContentWrapper, FormHelpSection, FormSectionBox } from 'components/Parts/Content';
import { Category } from 'model/category';
import { DomainEntry, FunnelCondition, FunnelConnection, TrafficSource } from 'model/models';
import { FunnelConditionRoute } from 'model/funnelConditionRoute';
import React, { PropsWithChildren } from 'react';
import { ConditionFormProps } from 'types/ModalForms/condition';
import { FFButton, FFCol, FFIcon, FFInput, FFRow, FFSelect, VisibilityWrapper } from 'uikit';
import { isCopyByContextModal } from 'utils/modals';
import className from 'utils/style/className';
import Messages from 'components/Messages';
import './style.scss';
import { Badge, DatePicker, Radio, TimePicker } from 'antd';
import { FunnelConditionRule } from 'model/funnelConditionRule';
import {
  ConditionRuleSelectParams,
  attributeFields,
  connectionTypeParams,
  continentSelectParams,
  countrySelectParams,
  dayOfMonthParams,
  dayOfWeekParams,
  deviceMainLanguageParams,
  deviceOsSelectParams,
  deviceTypeSelectParams,
  monthOfYearParams,
  timezoneSelectParams
} from './constants';
import { SidebarContext, SidebarsConsumer } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { CONDITION_CATEGORY_MODAL, UNCATEGORIZED } from 'constants/modal';
import { getActiveEntities, serverVersionIsAheadOfLocal, withIncrementedVersion } from 'utils/model';
import { withoutWhiteSpace } from 'utils/string';
import { defined } from 'utils/define';
import { formatSimpleToTimeFormat } from 'utils/time-helper';
import { countries } from 'constants/countries';
import { languages } from 'constants/languages';
import { daysOfWeek, getDefaultCondition } from 'constants/condition';
import { CONDITION_FORM_GET_ENTRANCE_LINK_TAB, CONDITION_FORM_HELP_TAB, CONDITION_FORM_TAB } from 'constants/dynamicSidebar';
import { asyncVersionConfirmSidebar, getVisibilityByActiveTab } from 'utils/dynamic-sidebar';
import { ENTRANCE_LINK_ELM_ID } from 'constants/builder';
import EntranceLink from '../Builder/EntranceLink';
import Icon from 'components/Icons';
import moment from 'moment';
import { DUPLICATE_RECORD_CODE } from 'constants/errors';
import { customDuplicateError } from 'utils/error';
import { generateEntityId } from 'utils/id/generator';
import { LoadingProps } from 'types/loading';
import { MessageProps } from 'types';
import fluxForm, { FluxFormErrors, FluxFormFieldRule } from 'packages/fluxForm';
import IconTooltip from 'uikit/components/IconTooltip';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import Tag from 'uikit/components/Tag';
import { sortBasedOnOrder, sortByName } from 'utils/sort';
import { Attribute } from 'model/attribute';

const { getClass } = className('c-ConditionNew');
const MESSAGE_MIN_COUNT_ROUTES = 'Error: condition nodes must contain at least one non-default route.';

const defaultRoute = (idRoute: number): FunnelConditionRoute => ({
  routeName: `Route ${idRoute}`,
  operator: 'or',
  groups: [
    {
      operator: 'or',
      rules: [
        {
          attribute: '' as any,
          test: '' as any
        }
      ]
    }
  ]
});

function getSelectParams(attribute: FunnelConditionRule.AttributeEnum, trafficSources: TrafficSource[] = [], domains: DomainEntry[] = []): ConditionRuleSelectParams {
  switch (attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
      return continentSelectParams;
    case FunnelConditionRule.AttributeEnum.LocationCountry:
      return countrySelectParams;
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
      return timezoneSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceType:
      return deviceTypeSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceOS:
      return deviceOsSelectParams;
    case FunnelConditionRule.AttributeEnum.DeviceMainLanguage:
      return deviceMainLanguageParams;
    case FunnelConditionRule.AttributeEnum.ConnectionType:
      return connectionTypeParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      return dayOfWeekParams;
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      return dayOfMonthParams;
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      return monthOfYearParams;
    case FunnelConditionRule.AttributeEnum.TrafficSource:
      return {
        defaultValueFallback: {
          label: 'Organic traffic',
          value: 'organic'
        },
        options: sortByName(trafficSources, 'trafficSourceName'),
        valueGetter: (opt: TrafficSource) => opt.idTrafficSource,
        labelGetter: (opt: TrafficSource) => opt.trafficSourceName
      };
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      return {
        options: sortBasedOnOrder(domains, 'domain'),
        valueGetter: (opt: DomainEntry) => opt.domain,
        labelGetter: (opt: DomainEntry) => opt.domain
      };
    default:
      return {
        options: [],
        labelGetter: opt => opt,
        valueGetter: opt => opt
      };
  }
}

const getErrorRules = (condition: FunnelCondition, conditionNames: string[] = []) => {
  const rules: FluxFormFieldRule[] = [];

  rules.push({
    required: 'This field should not be empty',
    path: 'conditionName',
    validator: (e: string) => !!e && conditionNames.includes(e) && 'This name is already taken'
  });

  condition.routes.forEach((route, idRoute) => {
    rules.push({ required: 'This field should not be empty', path: `routes.${idRoute}.routeName` });
    route.groups.forEach((group, idGroup) => {
      group.rules.forEach((rule, idRule) => {
        rules.push({ required: 'This field should not be empty', path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.attribute` });
        rules.push({ required: 'This field should not be empty', path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.test` });
        if (rule.attribute === FunnelConditionRule.AttributeEnum.TimeDate) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDateParams`
          });
        } else if (rule.attribute === FunnelConditionRule.AttributeEnum.TimeDayOfWeek) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfWeekParams.day`
          });
        } else if (rule.attribute === FunnelConditionRule.AttributeEnum.TimeDayOfMonth) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfMonthParams.day`
          });
        } else if (rule.attribute === FunnelConditionRule.AttributeEnum.TimeMonthOfYear) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeMonthOfYearParams.month`
          });
        } else if (rule.attribute === FunnelConditionRule.AttributeEnum.TimeTimeOfDay) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeOfDayParams`
          });
        } else if (rule.attribute === FunnelConditionRule.AttributeEnum.TrackingField) {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldName`
          });
        } else {
          rules.push({
            required: 'This field should not be empty',
            path: `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.genericParams.values`,
            validator: (e: string[]) => !e.length && 'This field should not be empty'
          });
        }
      });
    });
  });

  return rules;
};

const RuleComponents = ({
  idRoute,
  idGroup,
  idRule,
  errors,
  rule,
  condition,
  onChange,
  trafficSources,
  domains,
  children
}: PropsWithChildren<{
  idRoute: number;
  idGroup: number;
  idRule: number;
  rule: FunnelConditionRule;
  errors: FluxFormErrors;
  condition: FunnelCondition;
  domains: DomainEntry[];
  onChange: (condition: FunnelCondition) => void;
  trafficSources: TrafficSource[];
}>) => {
  const isPlural = [
    FunnelConditionRule.TestEnum.AnyIn,
    FunnelConditionRule.TestEnum.NotIn,
    FunnelConditionRule.TestEnum.Contains,
    FunnelConditionRule.TestEnum.DoesNotContain
  ].includes(rule.test);
  let path = '';
  let value = null;

  switch (rule.attribute) {
    case FunnelConditionRule.AttributeEnum.LocationContinent:
    case FunnelConditionRule.AttributeEnum.LocationCountry:
    case FunnelConditionRule.AttributeEnum.LocationTimezone:
    case FunnelConditionRule.AttributeEnum.DeviceType:
    case FunnelConditionRule.AttributeEnum.DeviceOS:
    case FunnelConditionRule.AttributeEnum.DeviceMainLanguage:
    case FunnelConditionRule.AttributeEnum.ConnectionType:
    case FunnelConditionRule.AttributeEnum.TrafficSource:
    case FunnelConditionRule.AttributeEnum.TrackingDomain:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.genericParams.values`;
      return (
        <>
          {children}
          <FFSelect
            {...getSelectParams(rule.attribute, trafficSources, domains)}
            onChange={values => {
              onChange(fluxForm.setDeepValue(condition, path, Array.isArray(values) ? values : [values]));
            }}
            value={fluxForm.getDeepValue(condition, path) || []}
            showSearch
            autoFocus
            placeholder="Select value(s)"
            className={getClass('selectValues')}
            mode={isPlural ? 'multiple' : undefined}
            error={errors[path]}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.LocationCity:
    case FunnelConditionRule.AttributeEnum.LocationRegion:
    case FunnelConditionRule.AttributeEnum.DeviceBrand:
    case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
    case FunnelConditionRule.AttributeEnum.DeviceModel:
    case FunnelConditionRule.AttributeEnum.DeviceBrowser:
    case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
    case FunnelConditionRule.AttributeEnum.ConnectionIP:
    case FunnelConditionRule.AttributeEnum.ConnectionISP:
    case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
    case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
    case FunnelConditionRule.AttributeEnum.ConnectionInitialReferrer:
    case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.genericParams.values`;
      return (
        <>
          {children}
          <FFInput
            error={errors[path]}
            onChange={e => {
              onChange(fluxForm.setDeepValue(condition, path, e.target.value.split(',')));
            }}
            value={(fluxForm.getDeepValue(condition, path) || []).join(',')}
            placeholder="Select value(s)"
            className={getClass('inputValues')}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDate:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDateParams`;
      value = fluxForm.getDeepValue(condition, path);
      return (
        <>
          {children}
          <DatePicker
            status={errors[path] ? 'error' : ''}
            onChange={date => {
              if (defined(date)) {
                onChange(
                  fluxForm.setDeepValue(condition, path, {
                    day: date.date(),
                    month: date.month() + 1,
                    year: date.year()
                  })
                );
              }
            }}
            value={defined(value) ? moment(`${value.year}.${value.month}.${value.day}`, 'YYYY-MM-DD') : undefined}
            showTime={false}
            format="DD.MM.YYYY"
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfWeekParams.day`;
      return (
        <>
          {children}
          <FFSelect
            {...getSelectParams(rule.attribute)}
            onChange={value => {
              onChange(fluxForm.setDeepValue(condition, path, value));
            }}
            value={fluxForm.getDeepValue(condition, path)}
            showSearch
            autoFocus
            placeholder="Select value(s)"
            className={getClass('selectValues')}
            mode={isPlural ? 'multiple' : undefined}
            error={errors[path]}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeDayOfMonthParams.day`;
      return (
        <>
          {children}
          <FFSelect
            {...getSelectParams(rule.attribute)}
            onChange={value => {
              onChange(fluxForm.setDeepValue(condition, path, Number(value)));
            }}
            value={fluxForm.getDeepValue(condition, path)}
            showSearch
            autoFocus
            placeholder="Select value(s)"
            className={getClass('selectValues')}
            mode={isPlural ? 'multiple' : undefined}
            error={errors[path]}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeMonthOfYearParams.month`;
      return (
        <>
          {children}
          <FFSelect
            {...getSelectParams(rule.attribute)}
            onChange={value => {
              onChange(fluxForm.setDeepValue(condition, path, Number(value)));
            }}
            showSearch
            autoFocus
            placeholder="Select value(s)"
            className={getClass('selectValues')}
            mode={isPlural ? 'multiple' : undefined}
            error={errors[path]}
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.timeOfDayParams`;
      value = fluxForm.getDeepValue(condition, path);
      return (
        <>
          {children}
          <TimePicker
            value={
              defined(value) && defined(value.hour) && defined(value.minutes)
                ? moment.utc(`${value.hour}:${value.minutes}`, 'HH:mm')
                : undefined
            }
            format="HH:mm"
            onChange={time => {
              const value = defined(time) ? { hour: time.hours(), minutes: time.minutes() } : { hour: null, minutes: null };
              onChange(fluxForm.setDeepValue(condition, path, value));
            }}
            placeholder="Select Time"
          />
        </>
      );
    case FunnelConditionRule.AttributeEnum.TrackingField:
      path = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldName`;
      const secondPath = `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.trackingFieldParams.fieldValues`;
      return (
        <>
          <FFInput
            value={fluxForm.getDeepValue(condition, path)}
            error={errors[path]}
            onChange={e => {
              onChange(fluxForm.setDeepValue(condition, path, e.target.value));
              if (!fluxForm.getDeepValue(condition, secondPath)) {
                onChange(fluxForm.setDeepValue(condition, secondPath, ['']));
              }
            }}
            placeholder="Enter Field Name"
          />
          {children}
          <FFInput
            error={errors[secondPath]}
            onChange={e => {
              onChange(fluxForm.setDeepValue(condition, secondPath, e.target.value.split(',')));
            }}
            value={(fluxForm.getDeepValue(condition, secondPath) || []).join(',')}
            placeholder="Enter Value(s)"
          />
        </>
      );
    default:
      return <></>;
  }
};

interface Props extends ConditionFormProps, Partial<LoadingProps>, MessageProps {}

interface State {
  condition: FunnelCondition;
  oldCondition: FunnelCondition;
  isNew: boolean;
  editingRouteId: number;
  conditionNames: string[];
  connections: FunnelConnection[];
  forDeleteConnections: FunnelConnection[];
  errors: FluxFormErrors;
  isFormSubmitted: boolean;
  dragStart: boolean;
  dragEnter?: string;
  dragLeave?: string;
  expanded: number[];
  isLocalCondition: boolean;
}

class ConditionNew extends React.Component<Props, State> {
  state: State = {
    condition: getDefaultCondition(),
    isNew: true,
    editingRouteId: -1,
    conditionNames: [],
    connections: [],
    forDeleteConnections: [],
    errors: {},
    isFormSubmitted: false,
    dragStart: false,
    dragEnter: undefined,
    dragLeave: undefined,
    expanded: [],
    oldCondition: getDefaultCondition(),
    isLocalCondition: false
  };

  static contextType = SidebarContext;
  context!: React.ContextType<typeof SidebarContext>;
  ruleChangeTimeout: NodeJS.Timeout = setTimeout(() => {}, 0);

  async componentDidMount() {
    this.props.setSidebarLoading!(true);
    this.props.fetchDomains();
    this.props.fetchTrafficSources();

    this.props.setButtonGroupProps!({
      showOk: true,
      showCancel: true,
      okText: isCopyByContextModal(this.props.contextModal) ? 'DUPLICATE' : 'SAVE',
      onOkClicked: this.onSubmit,
      onCancelClicked: this.onDiscard,
      cancelText: 'DISCARD'
    });

    await this.handleStateValues('connections', [...(this.props.funnel?.connections || [])]);

    this.setDefaultCondition();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.sidebarLoading && !this.props.sidebarLoading) {
      const id = this.props.contextModal.entityId! || this.props.funnelNode?.nodeConditionParams?.idCondition!;
      this.setEditModel(id);
    }
  }

  setDefaultCondition = async () => {
    const id = this.props.contextModal.entityId! || this.props.funnelNode?.nodeConditionParams?.idCondition!;
    await this.handleStateValues('condition', getDefaultCondition(this.props.data?.funnel?.idFunnel));
    await this.handleStateValues('oldCondition', getDefaultCondition(this.props.data?.funnel?.idFunnel));
    this.setForCopyIds(id);

    try {
      if (id) {
        await this.props.getConditionById(id);
        if (this.props.setOpenedAssets) {
          await this.props.setOpenedAssets(id, 'condition');
        }
        this.handleStateValues('isNew', false);
      } else {
        this.handleStateValues('isNew', true);
      }
      this.props.setSidebarLoading!(false);
    } catch (e) {
      this.handleStateValues('isNew', true);
      this.props.setSidebarLoading!(false);
    }

    await this.setEditModel(id);
    await this.handleStateValues('expanded', this.state.condition.routes.map((_, idx: number) => idx).slice(1, 4));
  };

  setEditModel = async (id: string) => {
    const condition = this.getCondition(id);

    if (defined(condition)) {
      const editModel = {
        ...(condition as FunnelCondition)
      };

      if (defined(editModel) && isCopyByContextModal(this.props.contextModal)) {
        editModel.conditionName = this.props.contextModal?.copyName || '';
      }
      this.setValue('conditionName', editModel.conditionName);
      this.setValue('idCategory', editModel.idCategory!);
      this.setValue('idCondition', editModel.idCondition);
      this.setValue('routes', editModel.routes);
      this.handleStateValues(
        'isLocalCondition',
        !!editModel.restrictToFunnelId || (defined(this.props.funnelNode) && !defined(this.props.conditionsArray[id]))
      );
      this.handleStateValues('oldCondition', editModel);
    }
  };

  getCondition = (id: string) => {
    let condition = this.props.data.conditionsArray?.[id] || this.props.conditionsArray?.[id];

    if (!condition?.routes?.length && defined(this.props.conditionsArray?.[id]) && this.props.conditionsArray?.[id]?.routes.length) {
      condition.routes = this.props.conditionsArray?.[id].routes || [];
    }
    if (this.props.data.conditionsArray?.[id]?.restrictToFunnelId) {
      condition.restrictToFunnelId = this.props.data.conditionsArray?.[id].restrictToFunnelId;
    }
    return condition;
  };

  setForCopyIds = (idCondition: string) => {
    if (this.props.setForCopyIdsProps) {
      this.props.setForCopyIdsProps({
        'Node ID': this.props.funnelNode?.idNode!,
        'Condition ID': idCondition
      });
    }
  };

  updateConnections = async (deletedRouteId: number) => {
    if (this.props.funnel?.connections && this.state.connections.length) {
      const deletedConnection = (this.props.funnel?.connections || []).find(
        connection =>
          connection.connectionConditionParams?.onRouteNumber === deletedRouteId &&
          connection.idSourceNode === this.props.funnelNode?.idNode
      );
      if (deletedConnection) {
        await this.handleStateValues('forDeleteConnections', [...this.state.forDeleteConnections, deletedConnection]);
      }

      await this.handleStateValues(
        'connections',
        this.state.connections
          .filter(connection => (deletedConnection ? connection.idConnection !== deletedConnection.idConnection : true))
          .map(connection => {
            if (
              connection.connectionConditionParams?.onRouteNumber &&
              connection.idSourceNode === this.props.funnelNode?.idNode &&
              connection.connectionConditionParams?.onRouteNumber > deletedRouteId
            ) {
              return {
                ...connection,
                connectionConditionParams: {
                  ...connection.connectionConditionParams,
                  onRouteNumber: connection.connectionConditionParams.onRouteNumber - 1
                }
              };
            } else {
              return connection;
            }
          })
      );
    }
  };

  onDiscard = async () => {
    await this.props.closeSelf();
  };

  onSubmit = async () => {
    this.handleStateValues('isFormSubmitted', true);
    this.updateRouteName();
    await this.handleStateValues(
      'errors',
      fluxForm.validator(this.state.condition, getErrorRules(this.state.condition, this.state.conditionNames))
    );
    if (fluxForm.hasErrors(this.state.errors)) {
      return false;
    }

    this.props.startLoading!('ok');
    if (await this.performCreateOrUpdate(this.state.condition)) {
      if (defined(this.props.onUpdateConnection)) {
        this.props.onUpdateConnection!(this.state.connections, this.state.forDeleteConnections);
      }

      this.props.closeSelf();
    }
  };

  performCreateOrUpdate = async (data: FunnelCondition) => {
    try {
      if (this.state.isNew) {
        await this.props.findConditionByName(
          {
            name: data.conditionName,
            idFunnel: data.restrictToFunnelId
          },
          customDuplicateError(DUPLICATE_RECORD_CODE, 'An Entity with same name exists')
        );
      }

      const idCondition = !data.idCondition || data.idCondition === '' ? generateEntityId() : data.idCondition;

      if (isCopyByContextModal(this.props.contextModal) && defined(this.props.handleDuplicate)) {
        await this.props.handleDuplicate({
          ...data,
          meta: { ...data.meta, version: 1 }
        });
      } else if (this.state.isNew) {
        await this.props.handleCreate({
          ...data,
          idCondition: idCondition,
          meta: { ...data.meta, version: 1 }
        });
      } else {
        await this.props.getConditionById(data.idCondition);
        if (data?.meta && serverVersionIsAheadOfLocal(data.meta?.version, this.props.conditionsArray[data.idCondition]?.meta?.version)) {
          const sidebar = this.context as SidebarProps;
          try {
            await asyncVersionConfirmSidebar(sidebar);
            await this.props.handleUpdate(withIncrementedVersion(data, this.props.conditionsArray[data.idCondition]?.meta?.version));
          } catch (e) {
            this.props.stopLoading!('all');
            this.onDiscard();
            return;
          }
        } else {
          await this.props.handleUpdate(withIncrementedVersion(data));
        }
      }

      this.props.stopLoading!('all');
      this.props.showMessage(Messages.success(`${data.conditionName} ${this.state.isNew ? 'has been added' : 'has been updated'}`));
      return true;
    } catch (e) {
      this.props.stopLoading!('all');
      if (defined(e.response) && e.response.status === DUPLICATE_RECORD_CODE) {
        this.handleStateValues('conditionNames', [...this.state.conditionNames, data.conditionName]);
      }

      this.props.showMessage(Messages.failed(`${data.conditionName} ${!!data.idCondition ? 'cannot be updated' : 'cannot be added'}`));
      return false;
    }
  };

  setValue = async (key: keyof FunnelCondition, value: any) => {
    clearTimeout(this.ruleChangeTimeout);
    this.ruleChangeTimeout = setTimeout(() => {
      if (this.state.isFormSubmitted) {
        this.handleStateValues('errors', fluxForm.validator(this.state.condition, getErrorRules(this.state.condition)));
      }
    }, 500);
    await this.setState(prevState => ({
      ...prevState,
      condition: {
        ...prevState.condition,
        [key]: value
      }
    }));
  };

  handleStateValues = <T extends State>(name: keyof T, value: T[keyof T]) => {
    this.setState((state: State) => ({
      ...state,
      [name]: value
    }));
  };

  getValues = <T extends keyof FunnelCondition>(key: T): FunnelCondition[T] => {
    return this.state.condition[key];
  };

  onAddRoute = () => {
    this.setValue('routes', [...this.getValues('routes'), defaultRoute(this.state.condition.routes.length)]);
    this.handleStateValues('expanded', [...this.state.expanded, this.state.condition.routes.length]);
  };

  onAddGroup = (idRoute: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            groups: [
              ...route.groups,
              {
                idGroup: '',
                groupName: '',
                operator: FunnelConditionRoute.OperatorEnum.Or,
                rules: []
              }
            ]
          };
        }
        return route;
      })
    );
  };

  onAddRule = (idRoute: number, idGroup: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            groups: route.groups.map((group, indexGroup) => {
              if (indexGroup === idGroup) {
                return {
                  ...group,
                  rules: [
                    ...group.rules,
                    {
                      attribute: '' as any,
                      test: '' as any
                    }
                  ]
                };
              }
              return group;
            })
          };
        }
        return route;
      })
    );
  };

  onDeleteRoute = async (idRoute: number) => {
    if (this.state.condition.routes.length === 2) {
      this.props.showMessage(Messages.failed(MESSAGE_MIN_COUNT_ROUTES));
      return;
    }
    await this.setValue(
      'routes',
      this.state.condition.routes.filter((_, index) => index !== idRoute)
    );
    await this.updateConnections(idRoute);
  };

  onDeleteRule = (idRoute: number, idGroup: number, idRule: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            groups: route.groups.map((group, indexGroup) => {
              if (indexGroup === idGroup) {
                return {
                  ...group,
                  rules: group.rules.filter((_, indexRule) => indexRule !== idRule)
                };
              }
              return group;
            })
          };
        }
        return route;
      })
    );
    requestAnimationFrame(() => this.updateRouteName());
  };

  onDeleteGroup = (idRoute: number, idGroup: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            groups: route.groups.filter((_, indexGroup) => indexGroup !== idGroup)
          };
        }
        return route;
      })
    );
    requestAnimationFrame(() => this.updateRouteName());
  };

  onChangeRuleField = (idRoute: number, idGroup: number, idRule: number, value: Attribute) => {
    this.setValue(
      'routes',
      fluxForm.setDeepValue(this.state.condition.routes, `${idRoute}.groups.${idGroup}.rules.${idRule}`, {
        attribute: value,
        test: '' as any,
        ...value === Attribute.ConnectionReferrer ? { genericParams: { values: [''] } } : {}
      } as FunnelConditionRule)
    );
    requestAnimationFrame(() => this.updateRouteName());
  };

  getTestOptions = (attribute: FunnelConditionRule.AttributeEnum) => {
    switch (attribute) {
      case FunnelConditionRule.AttributeEnum.LocationContinent:
      case FunnelConditionRule.AttributeEnum.LocationCountry:
      case FunnelConditionRule.AttributeEnum.LocationTimezone:
      case FunnelConditionRule.AttributeEnum.DeviceType:
      case FunnelConditionRule.AttributeEnum.DeviceOS:
      case FunnelConditionRule.AttributeEnum.DeviceMainLanguage:
      case FunnelConditionRule.AttributeEnum.ConnectionType:
      case FunnelConditionRule.AttributeEnum.TrafficSource:
        return ['is', 'is not', 'any in', 'not in'];
      case FunnelConditionRule.AttributeEnum.LocationCity:
      case FunnelConditionRule.AttributeEnum.LocationRegion:
      case FunnelConditionRule.AttributeEnum.DeviceBrowserVersion:
      case FunnelConditionRule.AttributeEnum.TimeDayOfWeek:
      case FunnelConditionRule.AttributeEnum.TimeDayOfMonth:
      case FunnelConditionRule.AttributeEnum.TimeMonthOfYear:
        return ['is', 'is not'];
      case FunnelConditionRule.AttributeEnum.DeviceBrand:
      case FunnelConditionRule.AttributeEnum.DeviceBrowser:
      case FunnelConditionRule.AttributeEnum.ConnectionISP:
      case FunnelConditionRule.AttributeEnum.ConnectionUserAgent:
      case FunnelConditionRule.AttributeEnum.ConnectionReferrer:
      case FunnelConditionRule.AttributeEnum.ConnectionInitialReferrer:
      case FunnelConditionRule.AttributeEnum.ConnectionCurrentURL:
      case FunnelConditionRule.AttributeEnum.TrackingField:
      case FunnelConditionRule.AttributeEnum.TrackingDomain:
        return ['is', 'is not', 'contains', 'does not contain'];
      case FunnelConditionRule.AttributeEnum.DeviceOSVersion:
        return ['is', 'is not', '<', '>'];
      case FunnelConditionRule.AttributeEnum.TimeDate:
      case FunnelConditionRule.AttributeEnum.TimeTimeOfDay:
        return ['<', '<=', 'is', '>', '>='];
      default:
        return ['is', 'is not'];
    }
  };

  getRouteNameByParam = (rule: FunnelConditionRule) => {
    if (defined(rule.genericParams)) {
      let result: string[] = [];
      if (rule.attribute === 'Traffic Source') {
        result = rule.genericParams!.values.map(item => {
          const trafficSource = this.props.trafficSources.find(trafficSource => trafficSource.idTrafficSource === item);
          return trafficSource?.trafficSourceName || '';
        });
      } else if (rule.attribute === 'Location: Country') {
        result = rule.genericParams!.values.map(item => {
          const country = countries.find(country => country.value === item);
          return country?.label || '';
        });
      } else if (rule.attribute === 'Device: Main Language') {
        result = rule.genericParams!.values.map(item => {
          const language = languages.find(language => language.value === item);
          return language?.label || '';
        });
      } else {
        result = rule.genericParams?.values || [];
      }
      return result.join(', ');
    } else if (defined(rule.timeDateParams)) {
      return withoutWhiteSpace(`
          ${rule.timeDateParams!.day}.
          ${rule.timeDateParams!.month}.
          ${rule.timeDateParams!.year}
        `);
    } else if (defined(rule.timeOfDayParams)) {
      if (defined(rule.timeOfDayParams) && (!defined(rule.timeOfDayParams!.hour) || !defined(rule.timeOfDayParams!.minutes))) {
        return '';
      }
      return withoutWhiteSpace(`
        ${formatSimpleToTimeFormat(rule.timeOfDayParams!.hour)}:
        ${formatSimpleToTimeFormat(rule.timeOfDayParams!.minutes)}
      `);
    } else if (defined(rule.timeDayOfWeekParams)) {
      return daysOfWeek.find(item => item.value === rule.timeDayOfWeekParams!.day)?.label;
    } else if (defined(rule.timeDayOfMonthParams)) {
      return `Day ${rule.timeDayOfMonthParams!.day} Of Month`;
    } else if (defined(rule.timeMonthOfYearParams)) {
      return monthOfYearParams.labelGetter(monthOfYearParams.options.find(item => Number(item) === rule.timeMonthOfYearParams!.month)!);
    } else if (defined(rule.trackingFieldParams)) {
      return ` ${rule.trackingFieldParams!.fieldName} ${rule.test || ''} ${rule.trackingFieldParams?.fieldValues || []}`;
    }

    return '';
  };

  isAutoRouteName = (route: FunnelConditionRoute) => route.routeName.startsWith('[') && route.routeName.endsWith(']');

  convertedTestEnum = (test: FunnelConditionRule.TestEnum) => {
    switch (test) {
      case 'is':
        return '=';
      case 'is not':
        return '!=';
      case 'not in':
        return '!=';
      case 'does not contain':
        return '!=';
      default:
        return test;
    }
  };

  getRouteName = (route: FunnelConditionRoute, idRoute: number) => {
    const groupIsEmpty = !defined(route.groups) || !route.groups.length;
    const ruleIsEmpty =
      !defined(route.groups[0]?.rules) || !route.groups?.[0]?.rules.length || !this.getRouteNameByParam(route.groups?.[0]?.rules?.[0]);

    if (!this.isAutoRouteName(route) && !/Route\s[0-9]{1,}$/g.test(route.routeName)) {
      return route.routeName;
    }
    if (groupIsEmpty || ruleIsEmpty) {
      return route.routeName || `Route ${idRoute}`;
    }
    if (route.groups.length > 1) {
      return 'Multiple rule group';
    }
    if (route.groups[0].rules.length > 2) {
      return 'Complex rule set';
    }

    return route.groups[0].rules.slice(0, 3).reduce((acc, rule, idRule) => {
      if (idRule === 2) {
        acc += '...';
      } else {
        acc += rule.attribute.includes(':') ? rule.attribute.split(':')?.[1] : rule.attribute;
        acc += rule.attribute === FunnelConditionRule.AttributeEnum.TrackingField ? '' : ` ${this.convertedTestEnum(rule.test) || ''} `;
        acc += this.getRouteNameByParam(rule);
        if (idRule < route.groups[0].rules.length - 1) {
          acc += route.groups[0].operator === 'or' ? ' OR ' : ' AND ';
        }
      }
      return `[${acc.trim()}]`;
    }, '');
  };

  updateRouteName = (condition: FunnelCondition = this.state.condition) => {
    this.setValue(
      'routes',
      condition.routes.map((route: FunnelConditionRoute, idRoute: number) => {
        return {
          ...route,
          routeName: this.getRouteName(route, idRoute)
        };
      })
    );
  };

  onOperatorChange = (idRoute: number, idGroup: number, idRule: number, value: string) => {
    this.setValue('routes', fluxForm.setDeepValue(this.state.condition.routes, `${idRoute}.groups.${idGroup}.rules.${idRule}.test`, value));
    this.updateRouteName();
  };

  onRouteNameChange = (e: React.ChangeEvent<HTMLInputElement>, idRoute: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            routeName: e.target.value
          };
        }
        return route;
      })
    );
  };

  onRouteRuleChange = (rule: FunnelConditionRoute.OperatorEnum, idRoute: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            operator: rule
          };
        }
        return route;
      })
    );
    requestAnimationFrame(() => this.updateRouteName());
  };

  onGroupRuleChange = (rule: FunnelConditionRoute.OperatorEnum, idRoute: number, idGroup: number) => {
    this.setValue(
      'routes',
      this.state.condition.routes.map((route: FunnelConditionRoute, index: number) => {
        if (index === idRoute) {
          return {
            ...route,
            groups: route.groups.map((group, indexGroup) => {
              if (indexGroup === idGroup) {
                return {
                  ...group,
                  operator: rule
                };
              }
              return group;
            })
          };
        }
        return route;
      })
    );
    requestAnimationFrame(() => this.updateRouteName());
  };

  onEnableRouteNameEditing = (idRoute: number) => {
    this.handleStateValues('editingRouteId', idRoute);
  };

  swapUp = (idRoute: number) => {
    if (idRoute === 1) return;
    const updatedItems = [...this.state.condition.routes];
    const [reorderedItem] = updatedItems.splice(idRoute, 1);
    updatedItems.splice(idRoute - 1, 0, reorderedItem);

    this.setValue('routes', updatedItems);
  };

  swapDown = (idRoute: number) => {
    if (idRoute === this.state.condition.routes.length - 1 || this.state.condition.routes.length === 2) return;
    const updatedItems = [...this.state.condition.routes];
    const [reorderedItem] = updatedItems.splice(idRoute, 1);
    updatedItems.splice(idRoute + 1, 0, reorderedItem);

    this.setValue('routes', updatedItems);
  };

  onDisableRouteNameEditing = () => {
    this.handleStateValues('editingRouteId', -1);
  };

  toggleRoute = (idRoute: number) => {
    const expanded = [...this.state.expanded];
    if (expanded.includes(idRoute)) {
      this.handleStateValues(
        'expanded',
        expanded.filter(route => route !== idRoute)
      );
    } else {
      this.handleStateValues('expanded', [...expanded, idRoute]);
    }
  };

  getRuleTestTag = (test: FunnelConditionRule.TestEnum) => {
    if (!test) return <></>;
    switch (test) {
      case 'is':
        return <Tag color="blue">=</Tag>;
      case 'is not':
        return <Tag color="volcano">!=</Tag>;
      case 'any in':
        return <Tag color="green">IN</Tag>;
      case 'not in':
        return <Tag color="gold">!IN</Tag>;
      case 'contains':
        return <Tag color="purple">CONTAINS</Tag>;
      case 'does not contain':
        return <Tag color="magenta">!CONTAIN</Tag>;
      default:
        return <Tag color="blue">{test}</Tag>;
    }
  };

  getDisplayedRouteName = (route: FunnelConditionRoute, idRoute: number) => {
    if (!this.isAutoRouteName(route) && !/Route\s[0-9]{1,}$/g.test(route.routeName)) {
      return <span>{route.routeName}</span>;
    }
    if (!route.groups?.length || !route.groups?.[0]?.rules.length || !route.groups?.[0]?.rules?.[0].attribute) {
      return route.routeName || `Route ${idRoute}`;
    }
    if (route.groups.length > 1) {
      return <span>Multiple rule group</span>;
    }
    if (route.groups[0].rules.length > 2) {
      return <span>Complex rule set</span>;
    }
    return route.groups[0].rules.slice(0, 3).map((rule, idRule) => {
      if (idRule === 2) {
        return <span> ...</span>;
      }
      let element;
      if (rule.attribute === 'Tracking Field') {
        element = (
          <span>
            Tracking Field {rule.trackingFieldParams?.fieldName} <span>{this.getRuleTestTag(rule.test)}</span>{' '}
            {rule.trackingFieldParams?.fieldValues}
          </span>
        );
      } else {
        element = (
          <span>
            {(rule.attribute || '').split(':')?.[1] || rule.attribute} {this.getRuleTestTag(rule.test)} {this.getRouteNameByParam(rule)}
          </span>
        );
      }

      if (idRule < route.groups[0].rules.length - 1 && idRule < 1) {
        element = (
          <>
            {element} {route.groups[0].operator === 'or' ? <span> | </span> : <span> & </span>}
          </>
        );
      }
      return element;
    });
  };

  render() {
    const isLocalCondition = this.state.isLocalCondition;

    if (this.props.sidebarLoading) return <Icon type="flux-rippleLoading" />;
    return (
      <>
        <SidebarsConsumer>
          {({ openSidebar }: SidebarProps) => (
            <FormContentWrapper show={getVisibilityByActiveTab(CONDITION_FORM_TAB, this.props.activeTab)}>
              <Badge.Ribbon
                color={!isLocalCondition ? 'blue' : 'green'}
                text={
                  !isLocalCondition ? (
                    <FFRow alignItems="center" gap="4px">
                      <FFIcon name="global" size="small" />
                      Global
                    </FFRow>
                  ) : (
                    <FFRow alignItems="center" gap="4px">
                      <FFIcon name="local" size="small" />
                      Local
                    </FFRow>
                  )
                }
                className={getClass('ribbon')}
              >
                <FormSectionBox title="General Settings">
                  <FFCol gap="12px">
                    <FFRow alignItems="center" gap="8px">
                      <FFCol>
                        <span>Name</span>
                      </FFCol>
                      <FFCol flex="1">
                        <FFInput
                          name="conditionName"
                          error={this.state.errors.conditionName}
                          value={this.getValues('conditionName')}
                          onChange={e => {
                            this.setValue('conditionName', e.target.value);
                          }}
                          placeholder="Condition Name"
                        />
                      </FFCol>
                    </FFRow>
                    <FFRow alignItems="center" gap="8px" display={isLocalCondition ? 'none' : 'flex'}>
                      <FFCol>
                        <span>Category</span>
                      </FFCol>
                      <FFCol flex="1">
                        <FFRow flex="1" alignItems="center" gap="8px">
                          <FFSelect
                            options={getActiveEntities(this.props.conditionsCategories || []).filter(category => category.idCategory)}
                            defaultValueFallback={{
                              label: UNCATEGORIZED,
                              value: ''
                            }}
                            value={this.getValues('idCategory')}
                            onSelect={value => this.setValue('idCategory', value)}
                            valueGetter={(opt: Category) => opt.idCategory}
                            labelGetter={(opt: Category) => opt.categoryName}
                            placeholder={UNCATEGORIZED}
                            error={this.state.errors.idCategory}
                            filterOption={true}
                            className={getClass('selectCategory')}
                          />
                          <FFIcon
                            name="plusCircle"
                            size="bigger"
                            className={getClass('addIcon')}
                            onClick={() => openSidebar(CONDITION_CATEGORY_MODAL)}
                          />
                        </FFRow>
                      </FFCol>
                    </FFRow>
                  </FFCol>
                </FormSectionBox>
              </Badge.Ribbon>

              <FormSectionBox withBoxPadding={false}>
                <FFCol>
                  <FFRow padding="20px 30px" backgroundColor="#f9fafc" borderBottom="1px solid #e1e6eb" gap="8px" alignItems="center">
                    <span>Routes</span>
                    <IconTooltip
                      key="Routes"
                      body="Routes correspond to the outgoing connections from your condition node. For each route you can define rules that must be matched. Routes process in the order in the list below."
                    />
                  </FFRow>
                  <FFRow alignItems="center" gap="24px" padding="16px" borderBottom="1px solid #e1e6eb">
                    <span className={getClass('index', 'default')}>0</span>
                    <span className={getClass('routeName', 'default')}>Default Route</span>
                  </FFRow>
                  {this.state.condition.routes.map((route: FunnelConditionRoute, idRoute: number) =>
                    idRoute === 0 ? (
                      <></>
                    ) : (
                      <FFCol
                        id={idRoute.toString()}
                        key={idRoute.toString()}
                        className={getClass('route', [
                          !this.state.expanded.includes(idRoute) && 'collapsed',
                          this.state.condition.routes[idRoute].groups.length > 1 && 'moreThanOneGroup'
                        ])}
                      >
                        <FFRow>
                          <FFCol width="100%">
                            <FFRow alignItems="center" justifyContent="space-between" height="35px">
                              <FFRow gap="8px" alignItems="center">
                                <FFRow gap="4px">
                                  <UpCircleOutlined className={getClass('shiftIcon')} onClick={() => this.swapUp(idRoute)} />
                                  <DownCircleOutlined className={getClass('shiftIcon')} onClick={() => this.swapDown(idRoute)} />
                                </FFRow>
                                <span className={getClass('index')}>{idRoute}</span>
                                {this.state.editingRouteId === idRoute ? (
                                  <FFInput
                                    error={this.state.errors[`routes.${idRoute}.routeName`]}
                                    placeholder="Route Name"
                                    autoFocus
                                    value={route.routeName}
                                    onBlur={this.onDisableRouteNameEditing}
                                    onChange={e => this.onRouteNameChange(e, idRoute)}
                                    className={getClass('routeNameInput')}
                                  />
                                ) : (
                                  <span className={getClass('routeName')}>{this.getDisplayedRouteName(route, idRoute)}</span>
                                )}
                                <FFIcon
                                  name="edit"
                                  size="small"
                                  className={getClass('editIcon')}
                                  style={{ display: 'flex' }}
                                  onClick={() => this.onEnableRouteNameEditing(idRoute)}
                                />
                              </FFRow>
                              <FFRow gap="8px" alignItems="center">
                                <FFIcon
                                  name="trash"
                                  size="small"
                                  className={getClass('deleteIcon')}
                                  onClick={() => this.onDeleteRoute(idRoute)}
                                />
                                <FFIcon
                                  name={!this.state.expanded.includes(idRoute) ? 'arrowDown' : 'arrowUp'}
                                  className={getClass('arrowIcon')}
                                  onClick={() => this.toggleRoute(idRoute)}
                                />
                              </FFRow>
                            </FFRow>
                          </FFCol>
                        </FFRow>
                        <VisibilityWrapper visible={this.state.expanded.includes(idRoute)}>
                          <FFRow className={getClass('groupsWrapper')}>
                            <FFRow className={getClass('groupsHeader')}>
                              <div>
                                <VisibilityWrapper visible={route.groups.length > 1}>
                                  <Radio.Group
                                    onChange={e => this.onRouteRuleChange(e.target.value, idRoute)}
                                    value={route.operator}
                                    buttonStyle="solid"
                                    className={getClass('routeOperator')}
                                  >
                                    <Radio.Button value="or">OR</Radio.Button>
                                    <Radio.Button value="and">AND</Radio.Button>
                                  </Radio.Group>
                                </VisibilityWrapper>
                              </div>
                              <FFButton
                                iconType="plusCircle"
                                iconSize="medium"
                                variant="primary"
                                className={getClass('addGroupButton')}
                                onClick={() => this.onAddGroup(idRoute)}
                              >
                                Group
                              </FFButton>
                            </FFRow>
                            {route.groups.map((group, idGroup) => (
                              <FFCol className={getClass('group', group.rules.length > 1 && 'moreThenOneRule')}>
                                <div className={getClass('groupInnerContent')}>
                                  <FFRow justifyContent="space-between" alignItems="center" marginBottom="12px">
                                    <div>
                                      <VisibilityWrapper visible={group.rules.length > 1}>
                                        <Radio.Group
                                          onChange={e => this.onGroupRuleChange(e.target.value, idRoute, idGroup)}
                                          value={group.operator}
                                          buttonStyle="solid"
                                          className={getClass('groupOperator')}
                                        >
                                          <Radio.Button value="or">OR</Radio.Button>
                                          <Radio.Button value="and">AND</Radio.Button>
                                        </Radio.Group>
                                      </VisibilityWrapper>
                                    </div>
                                    <FFRow alignItems="center" gap="12px">
                                      <FFButton
                                        iconType="plusCircle"
                                        iconSize="medium"
                                        variant="primary"
                                        className={getClass('addRuleButton')}
                                        onClick={() => this.onAddRule(idRoute, idGroup)}
                                      >
                                        Rule
                                      </FFButton>
                                      <FFIcon
                                        name="trash"
                                        size="small"
                                        className={getClass('deleteIcon')}
                                        onClick={() => this.onDeleteGroup(idRoute, idGroup)}
                                      />
                                    </FFRow>
                                  </FFRow>
                                  {group.rules.map((rule, idRule) => (
                                    <FFRow alignItems="center" gap="12px" marginBottom="12px">
                                      <FFCol flex="1">
                                        <FFRow flex="1" gap="8px">
                                          <FFSelect
                                            placeholder="Select attribute"
                                            dropdownMatchSelectWidth={false}
                                            options={attributeFields}
                                            valueGetter={opt => opt.value}
                                            labelGetter={opt => opt.label}
                                            value={fluxForm.getDeepValue(
                                              this.state.condition,
                                              `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.attribute`
                                            )}
                                            onSelect={value => this.onChangeRuleField(idRoute, idGroup, idRule, value)}
                                            groupOptions={true}
                                            sortGroup
                                            showSearch
                                            autoFocus
                                            error={this.state.errors[`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.attribute`]}
                                            className={getClass('selectField')}
                                          />

                                          <VisibilityWrapper visible={Boolean(rule.attribute)}>
                                            <RuleComponents
                                              rule={rule}
                                              idRoute={idRoute}
                                              idGroup={idGroup}
                                              idRule={idRule}
                                              errors={this.state.errors}
                                              condition={this.state.condition}
                                              trafficSources={this.props.trafficSources}
                                              domains={this.props.domains}
                                              onChange={this.updateRouteName}
                                            >
                                              <FFSelect
                                                onSelect={value => this.onOperatorChange(idRoute, idGroup, idRule, value)}
                                                value={fluxForm.getDeepValue(
                                                  this.state.condition,
                                                  `routes.${idRoute}.groups.${idGroup}.rules.${idRule}.test`
                                                )}
                                                placeholder="Operator"
                                                dropdownMatchSelectWidth={false}
                                                options={this.getTestOptions(rule.attribute)}
                                                valueGetter={opt => opt}
                                                labelGetter={opt => opt.toUpperCase()}
                                                sortGroup
                                                showSearch
                                                autoFocus
                                                error={this.state.errors[`routes.${idRoute}.groups.${idGroup}.rules.${idRule}.test`]}
                                                className={getClass('selectOperator')}
                                              />
                                            </RuleComponents>
                                          </VisibilityWrapper>
                                        </FFRow>
                                      </FFCol>
                                      <FFCol flexGrow={0}>
                                        <FFIcon
                                          name="trash"
                                          size="small"
                                          className={getClass('deleteIcon')}
                                          onClick={() => this.onDeleteRule(idRoute, idGroup, idRule)}
                                        />
                                      </FFCol>
                                    </FFRow>
                                  ))}
                                </div>
                              </FFCol>
                            ))}
                          </FFRow>
                        </VisibilityWrapper>
                      </FFCol>
                    )
                  )}
                  <FFRow alignItems="center" justifyContent="center" marginBottom="12px" marginTop="12px">
                    <FFButton iconSize="medium" iconType="plusCircle" variant="outlined" onClick={this.onAddRoute}>
                      Add Route
                    </FFButton>
                  </FFRow>
                </FFCol>
              </FormSectionBox>
            </FormContentWrapper>
          )}
        </SidebarsConsumer>

        <FormContentWrapper show={getVisibilityByActiveTab(CONDITION_FORM_GET_ENTRANCE_LINK_TAB, this.props.activeTab)}>
          <div id={ENTRANCE_LINK_ELM_ID}>
            <EntranceLink
              funnel={this.props.data?.funnel}
              funnelNode={this.props.funnelNode}
              trafficSources={this.props.data?.trafficSources}
              userSettings={this.props.data?.userSettings}
              openTsOnMount={this.props.shouldOpenLinkSection!}
              isChangedFunnel={this.props.isChangedFunnel!}
              domains={this.props.domains}
              switch="url"
            />
          </div>
        </FormContentWrapper>
        <FormContentWrapper show={getVisibilityByActiveTab(CONDITION_FORM_HELP_TAB, this.props.activeTab)}>
          <FormSectionBox title={this.props.tabTitle}>
            <FormHelpSection
              content={
                <>
                  <p>Conditions are nodes that you can use in funnels to make decisions.</p>

                  <p>You define "Routes" which are the connections you make in the funnel builder from this node to others.</p>

                  <p>Then, you define rules and criteria that must be met for that Route to be activated.</p>

                  <p>
                    Conditions process from the top down, so the earliest matching route will be returned. The default route will be
                    returned if none match.
                  </p>
                  <p>
                    Need more help? Read our documentation{' '}
                    <a href="https://help.funnelflux.pro/article/101" target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                  </p>
                </>
              }
              name="Condition"
            />
          </FormSectionBox>
        </FormContentWrapper>
      </>
    );
  }
}

export default Messages.injectIn(ConditionNew);
