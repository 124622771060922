import React from 'react';
import DynamicSideBar from 'components/DynamicSideBar';
import {
  fetchTrafficSources,
  fetchTrafficSourcesCategoryInfo
} from '../../../redux/actions/trafficsources';
import { CONDITION_MODAL, CONDITION_DUPLICATE_MODAL } from 'constants/modal';
import { connect } from 'react-redux';
import { ConditionModalSelector } from 'redux/selectors';
import { getModalTitle } from 'utils/modals';
import { FormContextSidebar } from 'types/modal';
import ConditionForm from '../../Forms/ConditionNew';
import { SidebarsConsumer } from '../context';
import { SidebarProps } from 'types/sidebars';
import {
  getConditionById,
  updateCondition,
  addCondition,
  duplicateCondition,
  fetchCategories,
  findConditionByName
} from 'redux/actions/conditions';
import { getDuplicateModalSidebarContext } from 'utils/copy';
import {
  ConditionFormProps,
  ConditionSidebarProps,
  ConditionTabKey
} from 'types/ModalForms/condition';
import { withLoading } from 'components/Loading';
import { LoadingProps } from 'types/loading';
import { defined } from 'utils/define';
import {
  CONDITION_FORM_TAB,
  CONDITION_FORM_GET_ENTRANCE_LINK_TAB,
  CONDITION_FORM_HELP_TAB
} from 'constants/dynamicSidebar';
import { AnyObject } from 'types';
import { fetchDomains } from 'redux/actions/domains';
import { FFIcon } from 'uikit';
import { getActiveEntities } from 'utils/model';

class ConditionFormWrapper extends React.Component<
  ConditionFormProps & ConditionSidebarProps & LoadingProps,
  {}
> {
  static defaultProps: Partial<ConditionSidebarProps> = {
    withWrapper: comp => comp
  };

  render() {
    return (
      <DynamicSideBar
        loading={this.props.loading}
        isOpen={this.props.isOpen!}
        hasTab={true}
        onClose={this.props.closeSelf!}
        defaultOpenSideBarKey="conditionForm"
        defaultActiveTab={
          this.props.shouldOpenLinkSection
            ? CONDITION_FORM_GET_ENTRANCE_LINK_TAB
            : CONDITION_FORM_TAB
        }
        dataPortalKey="condition"
        zIndex={this.props.zIndex}
        offsetRight={this.props.offsetRight}
        sideBars={[
          {
            key: 'conditionForm',
            bodyClassName: 'add-edit-condition condition',
            tabs: {
              [CONDITION_FORM_TAB]: {
                title: 'General Settings',
                icon: <FFIcon name="settings" />
              },
              [CONDITION_FORM_GET_ENTRANCE_LINK_TAB]: {
                title: 'Redirect Links',
                icon: <FFIcon name="redirectLink" />,
                isHidden: !defined(this.props.funnelNode)
              },
              [CONDITION_FORM_HELP_TAB]: {
                title: 'Help',
                icon: <FFIcon name="question" />
              }
            },
            title:
              this.props.customTitle ||
              `${getModalTitle(this.props.contextModal)} Condition`,
            render: ({
              activeTab,
              setButtonGroupProps,
              setLoading,
              setForCopyIdsProps,
              sidebarLoading,
              tabTitle
            }) => {
              return this.props.withWrapper!(
                <>
                  <ConditionForm
                    {...this.props}
                    activeTab={activeTab as ConditionTabKey}
                    setButtonGroupProps={setButtonGroupProps}
                    setForCopyIdsProps={setForCopyIdsProps}
                    setSidebarLoading={setLoading}
                    sidebarLoading={sidebarLoading}
                    tabTitle={tabTitle}
                    trafficSources={getActiveEntities(this.props.trafficSources)}
                  />
                </>
              );
            }
          }
        ]}
      />
    );
  }
}

export const FormElement = withLoading(ConditionFormWrapper);

const ConnectedFormElement = ({
  updateCondition,
  getConditionById,
  addCondition,
  duplicateCondition,
  categories,
  conditions,
  conditionsArray,
  trafficSources,
  fetchTrafficSources,
  scripts,
  domains,
  fetchCategories,
  fetchDomains,
  findConditionByName,
  fetchTrafficSourcesCategoryInfo
}: AnyObject) => {
  return (
    <SidebarsConsumer>
      {({
        isOpenSidebar,
        getContextSidebar,
        closeSidebar,
        getOffsetRight
      }: SidebarProps) => {
        let sidebarName = CONDITION_MODAL;
        let contextSidebar = getContextSidebar(CONDITION_MODAL);

        if (isOpenSidebar(CONDITION_DUPLICATE_MODAL)) {
          sidebarName = CONDITION_DUPLICATE_MODAL;
          contextSidebar = getContextSidebar(
            CONDITION_DUPLICATE_MODAL
          ) as FormContextSidebar;
          contextSidebar.copyName = getDuplicateModalSidebarContext(
            contextSidebar?.data?.id,
            conditions
          );
        }
        const isOpen =
          isOpenSidebar(CONDITION_MODAL) ||
          isOpenSidebar(CONDITION_DUPLICATE_MODAL);

        return (
          <FormElement
            findConditionByName={findConditionByName}
            fetchDomains={fetchDomains}
            fetchCategories={fetchCategories}
            conditionsCategories={categories}
            conditionsArray={conditionsArray}
            data={{
              conditionsArray
            }}
            handleCreate={addCondition}
            handleUpdate={updateCondition}
            handleDuplicate={duplicateCondition}
            contextModal={contextSidebar}
            getConditionById={getConditionById}
            trafficSources={trafficSources}
            fetchTrafficSources={fetchTrafficSources}
            domains={domains}
            closeSelf={() => closeSidebar(sidebarName)}
            isOpen={isOpen}
            zIndex={contextSidebar.zIndex!}
            offsetRight={getOffsetRight(sidebarName)}
          />
        );
      }}
    </SidebarsConsumer>
  );
};

export default connect(ConditionModalSelector, {
  updateCondition,
  getConditionById,
  addCondition,
  duplicateCondition,
  fetchTrafficSources,
  fetchCategories,
  fetchDomains,
  findConditionByName,
  fetchTrafficSourcesCategoryInfo
})(ConnectedFormElement);
