import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, RouteComponentProps, Link } from 'react-router-dom';
import { Button, ConfigProvider, notification } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'moment/locale/en-gb';
import PublicRoute from 'components/Routes/Public';
import PrivateRoute from 'components/Routes/Private';
import { sideMenu } from 'utils/menu';
import { logout } from 'redux/actions/auth';
import { changeView, setLastViewRoute, setSiderMenuIsExpanded } from 'redux/actions/navigation';
import { AppViewSelector } from './redux/selectors';
import Layout from 'components/NewLayout';
import ComponentHashTable from 'components';
import 'scss/App.scss';
import moment from 'moment';
import { deleteFunnelEditor } from './redux/actions/navigation';
import AdBlock from './components/AdBlock';
import { fetchAppVersion, updateUnreadChatCount } from './redux/actions/app';
import packageJson from '../package.json';
import { checkVersion, isJustExpired, isJustStandBy, loadGistAndSegmentScript } from './utils/app';
import { defined } from './utils/define';
import { fetchSystemSettings } from 'redux/actions/systemSettings';
import { isDevEnvironment } from './utils/app';
import { innerProgressBarPreloader, progressBarPreloader } from './utils/app/progressBar';
import { isString } from './utils/primitive';
import {
  DeleteFunnelEditor,
  ChangeView,
  SetLastViewRoute,
  FetchOnboardingStatus,
  FetchUser,
  TrackOnboardingStatus,
  SetSiderMenuIsExpanded,
  SetIsCloseAllFunnelTabsModalOpen,
  UpdateUnreadChatCount
} from 'types/actions';
import { User } from 'model/user';
import { AppStore } from 'types/redux/store';
import { OnboardingStatus, UserSettings } from 'model/models';
import { NavigationDetails, StoreNavigation } from 'types/navigation';
import { MenuItem } from 'types/menu';
import { fetchUser } from './redux/actions/user';
import { fetchOnboardingStatus, trackOnboarding } from './redux/actions/onboarding';
import { getTotalScore } from './utils/onboarding';
import Partners from 'components/SideBars/Partners';
//@ts-ignore
import ffWorker from 'workerize-loader!./webworkers/ffWorker.worker'; // eslint-disable-line import/no-webpack-loader-syntax
import { SidebarsConsumer } from 'components/SideBars/context';
import { SidebarProps } from 'types/sidebars';
import { WIZARD_MODAL } from 'constants/modal';
import { gistUtils } from 'widgets/gist';
import { closeAllFunnelBuilderTabs, setIsCloseAllFunnelTabsModalOpen } from 'redux/actions/funnels';
import { SUSPENSE_FALLBACK, SUSPENSE_FLAG, SUSPENSE_PRELOADER } from 'constants/progressBar';
import { VisibilityWrapper } from 'uikit';
import { SubscriptionStatus } from 'model/subscription';
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener.js';
import { AnyObject } from 'types';
import { trackSentry } from 'monitoring/sentry';
import { identifyCanny } from 'widgets/canny';
import SubscriptionAlert from 'components/SubscriptionAlert';

declare global {
  interface Window {
    gist: any;
    gistIsLoaded: boolean;
    gistUnreadCount: number;
    Canny: any;
    churnkey: any;
  }
}

export const ffWorkerInstance = ffWorker();
let swListener: any = null;

const appVersion = packageJson.version;

progressBarPreloader.start();
innerProgressBarPreloader.start(SUSPENSE_FLAG);
innerProgressBarPreloader.finishOnElementRemove(SUSPENSE_FLAG);

if (!isDevEnvironment) {
  const consoleWarn = window['console']['warn'];
  const consoleError = window['console']['error'];
  window['console']['warn'] = (string: string) => {
    if (
      (isString(string) &&
        string.indexOf('sizeColumnsToFit') === -1 &&
        string.indexOf('duplicate group keys') === -1 &&
        string.indexOf('getDataPath()') === -1) ||
      !isString(string)
    ) {
      consoleWarn(string);
    }
  };
  window['console']['error'] = (string: string) => {
    if ((isString(string) && string.indexOf('unable to find bean reference ') === -1) || !isString(string)) {
      consoleError(string);
    }
  };
}

interface Props {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  updateUnreadChatCount: UpdateUnreadChatCount;
  changeView: ChangeView;
  logout: () => void;
  closeAllFunnelBuilderTabs: () => void;
  user: User;
  subscriptionStatus: SubscriptionStatus;
  menu: NavigationDetails;
  deleteFunnelEditor: DeleteFunnelEditor;
  setLastViewRoute: SetLastViewRoute;
  lastViewRoute: StoreNavigation['lastViewRoute'];
  app: AppStore;
  settings: UserSettings;
  fetchSystemSettings: () => void;
  fetchAppVersion: () => void;
  match: RouteComponentProps['match'];
  fetchOnboardingStatus: FetchOnboardingStatus;
  trackOnboarding: TrackOnboardingStatus;
  onboardingStatus: OnboardingStatus;
  fetchUser: FetchUser;
  siderMenuIsExpanded: boolean;
  setSiderMenuIsExpanded: SetSiderMenuIsExpanded;
  setIsCloseAllFunnelTabsModalOpen: SetIsCloseAllFunnelTabsModalOpen;
}

interface State {
  showWizard: boolean;
  currentVersion: string;
  showPartners: boolean;
  isFetchFunnel: boolean;
  // service worker
  updateWaiting: boolean;
  registration: any;
  swListener: AnyObject;
  settingsInitialied: boolean;
}

moment.locale('en-gb');

class App extends PureComponent<Props, State> {
  state: State = {
    isFetchFunnel: false,
    showWizard: false,
    showPartners: false,
    currentVersion: '',
    //service worker
    updateWaiting: false,
    registration: null,
    swListener: {},
    settingsInitialied: false,
  };
  subscription: any = null;
  lastPathname = '';

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      innerProgressBarPreloader.start(SUSPENSE_FLAG);
      if (!this.props.location.pathname.includes('funneleditor')) {
        innerProgressBarPreloader.finishOnElementRemove(SUSPENSE_FLAG);
      }
    }

    if (this.props.user.loggedIn !== prevProps.user.loggedIn) {
      this.handleFetches();

      if (this.props.user.loggedIn) {
        identifyCanny(this.props.user);
      }
    }
  }

  handleStateValues = async <T extends State, P extends keyof T>(key: P, value: T[P]) => {
    await this.setState((state: State) => ({
      ...state,
      [key]: value
    }));
  };

  openNewVersionNotification = () => {
    const key = `newVersion${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="large"
        onClick={async () => {
          await this.state.registration.update();
          notification.close(key);
          this.openUpdatingNotification();
        }}
      >
        Reload
      </Button>
    );
    notification.open({
      className: 'newVersionNotification',
      message: 'Updated Version Available',
      description: 'Updates to the FunnelFlux Pro UI are now available. Please reload the page to get the latest version.',
      placement: 'bottomLeft',
      btn,
      key,
      duration: 30
    });
  };

  openUpdatingNotification = () => {
    const key = `updating${Date.now()}`;
    notification.open({
      className: 'updatingNotification',
      message: 'Updating Version',
      description: 'We are refreshing app data, please wait a moment...',
      placement: 'bottomLeft',
      key,
      duration: 30
    });
  };

  componentWillUnmount() {
    if (swListener) {
      swListener.removeEventListener();
    }
  }

  async componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      swListener = new ServiceWorkerUpdateListener();
      this.handleStateValues('swListener', swListener);
      swListener.onupdateinstalling = (installingEvent: any) => {
        console.log('SW installed', installingEvent);
      };
      swListener.onupdatewaiting = (waitingEvent: any) => {
        console.log('new update waiting', waitingEvent);
        swListener.skipWaiting(this.state.registration.waiting);
        this.handleStateValues('updateWaiting', true);
      };
      swListener.onupdateready = () => {
        console.log('updateready event');
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then(reg => {
        swListener.addRegistration(reg);
        this.handleStateValues('registration', reg);
        if (reg) reg.update();
      });
    }

    loadGistAndSegmentScript(this.props.user);

    document.addEventListener('onGistUnreadCountChange', () => {
      this.props.updateUnreadChatCount(window.gistUnreadCount);
    });

    if (this.props.user.loggedIn) {
      identifyCanny(this.props.user);
    }

    this.props.history.listen(location => {
      this.changePath(location.pathname);
      if (this.lastPathname !== location.pathname) {
        if (location.pathname !== '/' && this.lastPathname && this.lastPathname !== '/') {
          gistUtils.trackPageView();
        }

        this.lastPathname = location.pathname;
      }
    });

    await this.handleFetches();
    this.changePath(this.props.location.pathname);

    if (this.props.user.loggedIn) {
      if (this.props.location.pathname === '/') {
        this.props.history.push('/summary');
        this.props.changeView('Summary');
      }
    } else {
      this.props.history.push('/');
    }

    if (!defined(localStorage.getItem('timeZone'))) {
      await localStorage.setItem('timeZone', moment().format('Z'));
    }

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (
          mutation.type === 'childList' ||
          (mutation.type === 'attributes' &&
            //@ts-ignore
            mutation.target.id === 'suspense-fallback')
        ) {
          progressBarPreloader.finish();
          observer.disconnect();
        }
      }
    });
    observer.observe(document.getElementById('root')!, {
      attributes: true,
      childList: true,
      subtree: true
    });

    this.checkVersion();
  }

  checkVersion = async () => {
    try {
      const { hash } = await checkVersion();
      await this.handleStateValues('currentVersion', hash);

      setInterval(() => {
        checkVersion().then(({ hash }) => {
          if (this.state.currentVersion !== hash) {
            this.openNewVersionNotification();
          }
          this.handleStateValues('currentVersion', hash);
        });
      }, 5 * 60 * 1000);
    } catch (e) {
      trackSentry(e);
    }
  };

  handleFetches = async () => {
    if (this.props.user.loggedIn) {
      await Promise.all([this.props.fetchSystemSettings(), this.props.fetchUser()]);
      await this.handleStateValues('settingsInitialied', true);
    }
  };

  handleShowPartners = (show = true) => {
    this.setState(state => ({ ...state, showPartners: show }));
  };

  fetchAppVersion = async () => {
    await this.props.fetchAppVersion();
  };

  changePath = (pathName: string) => {
    const findPathWithId = (data: MenuItem) => {
      if (defined(data) && defined(data.items)) {
        return data.items.some(t => !!t.path.match(/\w.+id/) && !!pathName.match(/\w.+\d.+/g));
      }

      return undefined;
    };
    const view = sideMenu.find(s => {
      const pathWithId = findPathWithId((s.items || []).find(item => defined(item.items))!);
      if (pathWithId) {
        return pathWithId;
      } else {
        return (s.items || []).some(it => it.path === pathName);
      }
    })!;

    if (defined(view?.view)) {
      this.props.changeView(view.view);
    }
  };

  makeRoutes = () => {
    return sideMenu.reduce((acc: JSX.Element[], item) => {
      if (item && item.items) {
        item.items.forEach(route => {
          acc = [
            ...acc,
            <PrivateRoute
              exact
              key={`${item.view}-${route.path}`}
              path={route.path}
              location={this.props.location}
              component={ComponentHashTable.get(route.view)}
            />
          ];

          if (route && route.items) {
            route.items.forEach(subRoute => {
              acc = [
                ...acc,
                <PrivateRoute
                  exact
                  key={`${item.view}-${route.path}-${subRoute.path}`}
                  path={subRoute.path}
                  location={this.props.location}
                  component={ComponentHashTable.get(subRoute.view)}
                />
              ];
            });
          }
        });
      }
      return acc;
    }, []);
  };

  render() {
    const {
      logout,
      closeAllFunnelBuilderTabs,
      user,
      menu,
      deleteFunnelEditor,
      setLastViewRoute,
      lastViewRoute,
    } = this.props;
    const { currentActiveView, funnelEditorNavigation } = menu;
    const routes = this.makeRoutes();

    return (
      <ConfigProvider locale={en_GB}>
        <SidebarsConsumer>
          {({ openSidebar }: SidebarProps) => {
            return (
              <div className="App">
                <VisibilityWrapper visible={isJustExpired(this.props.subscriptionStatus)}>
                  <SubscriptionAlert type="warning">
                    Your subscription is inactive. Note expired subscriptions have data removed after 60 days.
                  </SubscriptionAlert>
                </VisibilityWrapper>
                <VisibilityWrapper visible={isJustStandBy(this.props.subscriptionStatus)}>
                  <SubscriptionAlert type="info">
                    You are currently on the Standby plan. Your data will not be deleted, but UI access is limited.
                  </SubscriptionAlert>
                </VisibilityWrapper>

                <Layout
                  user={user}
                  siderMenuIsExpanded={this.props.siderMenuIsExpanded}
                  setSiderMenuIsExpanded={this.props.setSiderMenuIsExpanded}
                  logout={() => {
                    closeAllFunnelBuilderTabs();
                    logout();
                  }}
                  unreadChatCount={this.props.app.unreadChatCount}
                  funnelEditorMenu={funnelEditorNavigation}
                  currentActiveItem={currentActiveView}
                  deleteFunnelEditor={deleteFunnelEditor}
                  setLastViewRoute={setLastViewRoute}
                  lastViewRoute={lastViewRoute}
                  changeView={this.props.changeView}
                  changePath={this.changePath}
                  subscriptionStatus={this.props.subscriptionStatus}
                  handleShowWizard={() => openSidebar(WIZARD_MODAL)}
                  handleShowPartners={this.handleShowPartners}
                  onboardingCurrentScore={this.props.onboardingStatus.totalScore}
                  onboardingTotalScore={getTotalScore(this.props.onboardingStatus.tasks)}
                  setIsCloseAllFunnelTabsModalOpen={this.props.setIsCloseAllFunnelTabsModalOpen}
                >
                  <Switch>
                    <PublicRoute exact path="/" component={ComponentHashTable.get('LoginView')} location={this.props.location} />
                    {this.state.settingsInitialied && routes}
                  </Switch>

                  <div id={SUSPENSE_FALLBACK} className={innerProgressBarPreloader.finishLoading ? 'hidden' : ''}>
                    <div id="suspense-fallback-block" />
                    <div id="suspense-fallback-container">
                      <div id={SUSPENSE_PRELOADER} />
                    </div>
                  </div>

                  <AdBlock pathname={window.location.pathname} />
                  <Partners isOpen={this.state.showPartners} onClose={() => this.handleShowPartners(false)} />
                </Layout>
              </div>
            );
          }}
        </SidebarsConsumer>
      </ConfigProvider>
    );
  }
}

export default withRouter(
  connect(AppViewSelector, {
    logout,
    changeView,
    deleteFunnelEditor,
    setLastViewRoute,
    fetchAppVersion,
    fetchSystemSettings,
    fetchOnboardingStatus,
    trackOnboarding,
    setSiderMenuIsExpanded,
    fetchUser,
    setIsCloseAllFunnelTabsModalOpen,
    closeAllFunnelBuilderTabs,
    updateUnreadChatCount
  })(App)
);
